import gsap from 'gsap';
import Viewport from '../core/Viewport';
import Ticker from '../core/Ticker';

export default (canvas, props) => {
    const ctx = canvas.getContext('2d');
    let imgBg;
    let imgDrops;
    let x = 0;
    let y = 0;
    let noOfDrops = Viewport.breakpoint.size < 900 ? 3 : 8;
    let fallingDrops = [];

    let myTicker = null;

    const imgs = [
        '/assets/static/img/jul/Vaersaagod_Julekule_A_small.png',
        '/assets/static/img/jul/Vaersaagod_Julekule_B_small.png',
        '/assets/static/img/jul/Vaersaagod_Julekule_C_small.png',
        '/assets/static/img/jul/Vaersaagod_Nisselue_A1_small.png',
        '/assets/static/img/jul/Vaersaagod_Nisselue_B1_small.png'
    ];

    const init = () => {
        canvas.width = Viewport.width;
        canvas.height = Viewport.height;

        for (let i = 0; i < noOfDrops; i++) {
            const fallingDr = {};
            fallingDr.image = new Image();
            fallingDr.image.src = imgs[Math.floor(imgs.length * Math.random())];
            const size = 90 * (Math.floor(50 * Math.random()));
            fallingDr.image.width = size;
            fallingDr.image.height = size;

            fallingDr["x"] = Math.random() * (Viewport.width * 0.9);
            fallingDr["y"] = -((Viewport.height) * Math.random()) - 100;
            fallingDr["speed"] = 1 + (Math.random() * 2);
            fallingDrops.push(fallingDr);
        }

        myTicker = new Ticker(draw, {
            fps: 45 // default 60
        });
        
        Viewport.on('resize', onResize);
    };
    
    const onResize = e => {
        canvas.width = Viewport.width;
        canvas.height = Viewport.height;
    };

    function draw() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        for (let i = 0; i < noOfDrops; i++) {
            ctx.drawImage(fallingDrops[i].image, fallingDrops[i].x, fallingDrops[i].y, 100, 100); //The rain drop

            fallingDrops[i].y += fallingDrops[i].speed; //Set the falling speed

            if (fallingDrops[i].y > Viewport.height + 100) {  //Repeat the raindrop when it falls out of view
                fallingDrops[i].y = -200-(Math.random()*200) //Account for the image size
                fallingDrops[i].x = Math.random() * (Viewport.width*0.9);    //Make it appear randomly along the width    
            }
        }
    }

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
