/*

 TICKER


 ~ Usage ~


 import Ticker from '@vaersaagod/tools/Ticker';

 const myTicker = new Ticker(tickHandler, {
    fps: 30 // default 60
 });

 myTicker.start();
 myTicker.pause();
 myTicker.destroy();
 myTicker.update({fps: 40});

 Ticker.startAll();
 Ticker.resumeAll();
 Ticker.destroyAll();

 */

const DEFAULT_OPTS = {
    fps: 60,
    paused: true
};

export class Ticker {

    static tickers = [];
    static paused = false;
    static raf = null;

    constructor(cb, opts = {}) {

        this.cb = cb;
        this.opts = Object.assign({}, DEFAULT_OPTS, opts);

        if (!Ticker.tickers.length) {
            Ticker.startAll();
        }

        Ticker.tickers.push(this);

    }

    static pauseAll() {

        if (this.raf) {
            cancelAnimationFrame(this.raf);
            this.raf = null;
        }

        Ticker.paused = true;

    }

    static startAll() {
        this.raf = tick();
        Ticker.paused = false;
    }

    static destroyAll() {
        Ticker.pauseAll();
        Ticker.tickers = [];
    }

    static onTick() {

        const now = new Date().getTime();

        let interval,
            delta,
            ticker,
            numTickers = Ticker.tickers.length;

        for (let i = 0; i < numTickers; ++i) {
            ticker = Ticker.tickers[i];

            if (ticker.paused) {
                continue;
            }

            interval = 1000 / ticker.opts.fps;
            delta = ticker.lastRun ? now - ticker.lastRun : null;

            if (delta === null || delta > interval) {
                ticker.lastRun = now - (delta % interval);
                ticker.cb();
            }

        }

        // Tick again
        if (!Ticker.paused) {
            Ticker.raf = tick();
        }

    }

    pause() {
        this.opts.paused = true;
    }

    start() {
        this.opts.paused = false;
    }

    destroy() {
        const index = Ticker.tickers.indexOf(this);
        Ticker.tickers.splice(index, 1);
    }

    update(opts = {}) {
        // TODO - check if this works as expected :/
        this.opts = Object.assign(DEFAULT_OPTS, this.opts || {}, opts);
    }

}
;

const tick = () => requestAnimationFrame(() => {
    Ticker.onTick();
});

export default Ticker;
